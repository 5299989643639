import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

const router = new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            redirect: '/index'
        },
        {
            path: '/index',
            name: 'Index',
            component: () => import('@/views/index/home/view/index/home'),
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/index/policy_market',
            name: 'policy_market',
            component: () => import('@/views/index/home/view/index/policy_market'),
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/user',
            name: 'user',
            component: () => import('@/views/index/home/view/user/home'),
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/content/detail',
            name: 'detail',
            component: () => import('@/views/index/home/view/content/detail'),
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/content/exempt_apply_list',
            name: 'exempt_apply_list',
            component: () => import('@/views/index/home/view/content/exempt_apply_list'),
            meta: {
                keepAlive: true
            }
        },
        {
            path: '*',
            redirect: '/error'
        }]
})


export default router