<template>
  <div class="content_view">
    <div class="main">
      <login :isLogin="isLogin" :username="username"></login>
      <div class="top">
        <div class="logo">
          <v-img :src="require('../../index/image/top_title.png')"></v-img>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="search">
        <div class="search-box">
          <el-input size="large" placeholder="请输入条款关键字..." v-model="vagueName" class="input-with-select">
            <el-button slot="append" class="select_btn" style="
                background-color: #4178b5;
                color: white;
                font-size: 20px;
                padding: 17px 24px;
              " icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
      </div>
    </div>
    <div class="main-box">
      <div class="msjx">
        <div class="msjx-top">
          <div class="msjx-top-left">
            <v-img :src="require('../../index/image/msjx.png')" width="117"></v-img>
          </div>
          <div class="msjx-top-right" @click="goToView('content/exempt_apply_list');">
            查看更多
            <el-image style="" :src="require('../../index/img/moer.png')"></el-image>
          </div>
        </div>
        <div class="msjx-content">
          <div class="arrow_view">
            <div class="left_btn" @click="switchCarousel(1)"><i class="el-icon-arrow-left"></i></div>
            <div class="right_btn" @click="switchCarousel(0)"><i class="el-icon-arrow-right"></i></div>
          </div>
          <el-carousel indicator-position="none" height="131px" arrow="never" ref="carouselRef">
            <el-carousel-item v-for="(carousel, index) in carouselList" :key="'carousel' + index">
              <el-row :gutter="15">
                <el-col :span="8" v-for="(item, ind) in carousel" :key="'col' + ind" class="carousel-item">
                  <div class="msjx-item" @click="onItem(item)">
                    <div class="msjx-item-left">
                      <div class="msjx-item-left-day">
                        {{ item.printedTime.substring(8, 10) }}
                      </div>
                      <div class="msjx-item-left-line"></div>
                      <div class="msjx-item-left-month">
                        {{ item.printedTime.substring(0, 7) }}
                      </div>
                    </div>
                    <div class="msjx-item-right">
                      <div class="msjx-item-title">
                        {{ item.clauseName }}
                      </div>
                      <div class="msjx-item-btn">
                        <div class="valid_text" v-if="item.validEndTime">条款有效期至：{{ item.validEndTime }}</div>
                        <el-image :src="require('../../index/image/tiaozhuan.png')"></el-image>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "@/views/index/login";

export default {
  data: () => {
    return {
      carouselIndex: 0,
      username: "",
      msjxList: [],
      supportTypeList: [],
      vagueName: "",
      isLogin: false,
    }
  },
  components: { Login },
  mounted() {
    this.vagueName = this.$route.query.vagueName;
    this.username = window.sessionStorage.getItem("username");
    var loginflag = this.$route.query.loginflag;
    this.isLogin = (loginflag === "true" || this.username);
    if (!this.isLogin && !loginflag) {
      let redirect_uri = window.location.href;
      if (window.location.hash === "") redirect_uri += "/#/index";
      redirect_uri = encodeURIComponent(redirect_uri);
      let callerCode = "2c9bbe698172a06201824295293e0b5f2c9bbe698172a062";
      let url = `https://mztapp.fujian.gov.cn:8304/dataset/UnifiedController/checkLoginStatus.do?checkbackurl=${redirect_uri}&callerCode=${callerCode}`;
      window.location.href = url;
    } else if (this.isLogin && !this.username) {
      var trustticket = this.$route.query.trustticket;
      var userdata = {};
      userdata.TRUST_TICKET = trustticket;
      console.log("test" + this.$http.adornUrl("/app/auth/getUserInfo"));
      this.$http({
        url: "https://lyhqpt.com/ming/app/auth/getUserInfo",
        //   url:this.$http.adornUrl('/app/auth/getUserInfo'),
        method: "post",
        data: this.$http.adornData(userdata),
      }).then((result) => {
        if (result.code === 0) {
          if (result.username != null) {
            this.isLogin = true;
            this.username = result.username;
            window.sessionStorage.setItem("username", result.username);
            window.sessionStorage.setItem("tycode", result.tycode);
            console.log('登录完成')
            this.$http({
              url: this.$http.adornUrl("/app/createToken") + `?username=${result.username}&tycode=${result.tycode}`,
              method: "get",
            }).then((tokenRes) => {
              if (tokenRes.code === 0) {
                console.log(tokenRes.token)
                window.sessionStorage.setItem('token', tokenRes.token)
              }
            })
          }
        }
      });
    }
    this.getMsjxList();
  },
  methods: {
    goToView(url) {
      window.location.href = `/#/${url}`;
    },
    search() {
      if (this.vagueName) {

        window.location.href = '/#/index/policy_market?vagueName=' + this.vagueName;
        if (this.$route.name === "policy_market") this.$router.go(0)
      } else this.$alert('请输入搜索内容后重新搜索', '提示', {
        confirmButtonText: '确定',
        type: "warning"
      });
    },
    onItem(item) {
      window.location.href = '/#/content/detail?id=' + item.id;
      if (this.$route.name === "detail") this.$router.go(0)
    },
    switchCarousel(type = 1) {
      if (type === 1) {
        if (this.carouselIndex === 0) {
          this.carouselIndex = this.carouselList.length - 1
        } else {
          this.carouselIndex--
        }
        this.$refs.carouselRef.setActiveItem(this.carouselIndex)
      } else {
        if (this.carouselIndex < this.carouselList.length - 1) {
          this.carouselIndex++
        } else {
          this.carouselIndex = 0
        }
        this.$refs.carouselRef.setActiveItem(this.carouselIndex)
      }
    },
    getMsjxList() {
      this.$http({
        url: this.$http.adornUrl("/app/huiqiclause/msjxList"),
        method: "post",
        data: this.$http.adornData({ page: 1, limit: 12, sort: 'desc' }),
      }).then((result) => {
        if (result.code === 0) {
          if (Array.isArray(result.page.list)) {
            this.msjxList = this.msjxList.concat(result.page.list);
          } else {
            this.msjxList.push(result.page.list);
          }
        }
      });
    },
  },
  computed: {
    carouselList() {
      let list = JSON.parse(JSON.stringify(this.msjxList));
      let result = [];
      let l = [];
      while (list.length > 0) {
        let d = list.shift();
        l.push(d);
        if (l.length >= 3) {
          result.push(JSON.parse(JSON.stringify(l)));
          l = [];
        }
      }
      if (l.length > 0) {
        result.push(JSON.parse(JSON.stringify(l)));
      }
      return result;
    },
  },
}
</script>

<style scoped lang="scss">
.content_view {
  height: 620px;
  position: relative;

  background: #f0f4f8 url("../../index/image/top_bg.png") no-repeat;
}

.main-box {
  background-color: rgba($color: #1156a3, $alpha: 0.5);
  width: 100%;
  position: absolute;
  bottom: 0;

  .msjx {
    max-width: 1300px;
    margin: 0 auto;

    //margin-top: 74px;
    border-radius: 10px;
    // background-color: white;
    padding: 20px 0;


    a {
      text-decoration: none;
    }

    .msjx-top {
      display: flex;

      .msjx-top-left {
        flex-grow: 1;
      }

      .msjx-top-right {
        line-height: 1;
        color: #fff;
        cursor: pointer;
        user-select: none;

        &:hover {
          opacity: 0.8;
        }

        .el-image {
          vertical-align: middle;
          margin-left: 5px;
        }
      }
    }

    .msjx-content {
      margin-top: 42px;
      position: relative;

      .carousel-item {
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .arrow_view {
        position: absolute;
        width: 110%;
        left: -5%;
        top: 50%;
        transform: translateY(-50%);
        height: 40px;
        //z-index: 10;

        i {
          font-size: 16px;
          color: #1156A3;
        }

        .left_btn {
          width: 40px;
          height: 40px;
          background: #FFFFFF;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          user-select: none;

          &:hover {
            opacity: 0.8;
          }
        }

        .right_btn {
          width: 40px;
          height: 40px;
          background: #FFFFFF;
          border-radius: 50%;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .msjx-item {
        padding: 20px;
        background-color: #fff;
        border-radius: 0px;
        display: flex;
        width: 100%;
        height: 131px;

        .msjx-item-left {
          width: 80px;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          color: #999;
          user-select: none;

          .msjx-item-left-day {
            font-size: 32px;
          }

          .msjx-item-left-line {
            border-bottom: solid 1px #999;
            width: 20px;
            margin: 5px 0;
          }

          .msjx-item-left-month {
            font-size: 14px;
          }
        }

        .msjx-item-right {
          flex-grow: 1;
        }

        .msjx-item-title {
          font-size: 18px;
          line-height: 1.5;
          height: 54px;
          color: #333;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          user-select: none;
        }

        .msjx-item-date {
          margin-top: 12px;
          font-size: 16px;
          color: #999999;
        }

        .msjx-item-btn {
          text-align: right;
          display: flex;
          align-items: center;
          user-select: none;

          .valid_text {
            flex: 1;
            text-align: left;

            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 42px;
          }

          .el-image {
            width: 20px;
            height: 14px;
          }
        }
      }
    }
  }
}

.main {
  max-width: 1300px;
  margin: 0 auto;

  .top {
    padding-top: 96px;
    padding-bottom: 70px;

    .logo {
      width: 474px;
      margin: 0 auto;
    }
  }

  .search {
    .search-box {
      background-color: white;
      max-width: 900px;
      margin: 0 auto;
      border-radius: 4px;
      overflow: hidden;
      user-select: none;
      padding: 1px 0 1px 1px;

      .select_btn {
        cursor: pointer;
        user-select: none;

        &:hover {
          opacity: 0.8;
        }
      }

      ::v-deep .el-input__inner {
        height: 54px;
        line-height: 54px;
      }

      ::v-deep .el-input__inner::placeholder {
        font-size: 18px;
        color: #aaaaaa;
      }
    }
  }

  .content {
    padding: 20px 0;
    background-color: white;
    // border-radius: 10px;
    margin-top: 20px;

    ::v-deep .el-tabs__item {
      font-size: 24px;
      height: 50px;
      position: relative;
    }

    ::v-deep .el-tabs__active-bar {
      background-color: #1156a3;
    }

    ::v-deep .el-tabs__item.is-active {
      font-weight: bold;
      color: #1156a3;
    }

    ::v-deep .el-tabs__content {
      overflow: inherit;
    }

    .tab-item {
      position: relative;

      .search-box {
        position: absolute;
        width: 500px;
        top: -64px;
        right: 0;
        z-index: 10;
      }

      .filter-box {
        display: flex;
        position: relative;
        padding: 0 20px;

        .filter-col {
          margin: 0 5px;
          flex-grow: 1;
          flex-shrink: 0;
          width: 240px;
        }

        .filter-item {
          width: 100%;
          border-radius: 8px;
          position: relative;
          cursor: pointer;

          .filter-item-main {
            opacity: 0.5;
          }

          .filter-item__checked {
            display: none;
            position: absolute;
            bottom: 4px;
            left: 0;
            right: 0;
            text-align: center;
            height: 8px;
          }
        }

        .filter-item.checked {
          .filter-item-main {
            opacity: 1;
          }

          .filter-item__checked {
            display: block;
          }
        }
      }

      .filter-item-box {
        margin-top: 10px;
        padding: 0 20px;

        .filter-item-list-item {
          // position: relative;
          // display:inline-block;
          padding: 6px 20px;
          font-size: 16px;
          height: auto;
          // line-height: 1;
          margin: 0 5px 12px;
          cursor: pointer;
          // z-index: 1;
          // border-radius: 4px;
          // background-color: white;
        }

        .filter-item-list-item:hover {
          background-color: #d9ecff;
        }
      }

      .filter-result {
        padding: 0 20px;
        display: flex;
        width: 100%;
        margin-top: 0px;
        padding-bottom: 8px;
        border-bottom: solid 1px #dddddd;

        .filter-result-left {
          flex-shrink: 0;
        }

        .filter-result-center {
          flex-grow: 1;
          flex-shrink: 0;
          overflow: hidden;
          width: 0;
          white-space: nowrap;

          .el-tag {
            margin-right: 10px;
            cursor: pointer;
          }
        }

        .filter-result-right {
          flex-shrink: 0;
          display: flex;
          color: #888888;
          line-height: 1;

          .fr-right-left {
            margin: 0 5px;
            padding-top: 5px;
            cursor: pointer;
          }

          .fr-right-center {
            margin: 0 5px;
            padding-top: 5px;
            cursor: pointer;
          }

          .fr-right-right {
            padding-top: 3px;
            margin: 0 5px 0 20px;
          }
        }
      }

      .policy-list {
        margin-top: 10px;

        .policy-item:hover {
          background-color: #f5f5f5;
        }

        .policy-item {
          padding: 10px 20px 20px;
          display: flex;
          border-bottom: solid 1px #e5e5e5;
          cursor: pointer;
          align-items: center;

          .item_btn {
            width: 144px;
            height: 48px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.blue {
              background: #1156A3;
              color: #FFFFFF;
            }

            &.white {
              border: 1px solid #1156A3;
              background: #fff;
              color: #1156A3;
            }
          }

          .item-left {
            flex-grow: 1;
            padding-right: 20px;
            line-height: 1.5;
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 0;

            .title {
              font-size: 26px;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .tag {
              margin-top: 10px;
              width: 100%;
              overflow: hidden;

              .el-tag {
                margin-right: 10px;
                display: inline-block;
              }
            }

            .info {
              display: flex;
              font-size: 16px;
              color: #888;
              margin-top: 10px;

              .el-image {
                vertical-align: middle;
              }

              .info-center {
                margin-left: 30px;
                max-width: 600px;
              }

              .info-right {
                text-align: left;
                margin-left: 30px;
              }
            }
          }

          .item-right {
            width: 200px;
            background: url("../../index/image/list_yxq_bg.png");
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            flex-direction: column;

            .time {
              font-size: 20px;
              color: white;
              padding: 20px;
              text-align: center;
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              line-height: 1.5;
            }

            .tip {
              font-size: 24px;
              color: #eee;
              background-color: #4178b5;
              text-align: center;
              padding: 12px 0;
            }
          }
        }
      }
    }
  }
}
</style>