import axios from "axios"
import store from '@/store/index'
import * as types from '@/store/mutation-types'
import merge from 'lodash/merge'
import qs from 'qs'
import { Message } from "element-ui"

import { sessionGetItem, sessionRemoveItem } from '@/utils';
// import router from "../router";
axios.defaults.withCredentials = true
const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_SERVICE_API,
    timeout: 30000, // 请求超时时间
    // xhrFields: {withCredentials: true},
    headers: {
        'Content-Type': 'application/json'
    },
})

http.interceptors.request.use(config => {
    // window.console.log(process.env.VUE_APP_BASE_SERVICE_API)
    let Cancel = axios.CancelToken;
    config.cancelToken = new Cancel(function (c) {
        store.commit(types.PUSH_TOKEN, c)
    })
    const token = sessionGetItem('token');
    if (token) {
        config.headers['token'] = token;
    }
    return config
}, error => {
    Promise.reject(error)
})

http.interceptors.response.use(
    response => {
        const res = response.data
        let code = response.status
        if (code == 200) {
            // if(res.code==401){
            //     Message.error('登录超时，请重新登录')
            //     router.replace({
            //         path:'/login',
            //         query:{
            //             fromRouterPath:router.currentRoute.fullPath
            //         }
            //     })
            // }
            if (res.code == 400 || res.code == 401 || res.code == 402) {
                Message.error('token失效')
                sessionRemoveItem('token')
                sessionRemoveItem('username')
                sessionRemoveItem('tycode')
                let origin = window.location.origin
                let redirect_uri = encodeURIComponent(origin + "/#/index")
                window.location.href = `https://mztapp.fujian.gov.cn:8304/dataset/UnifiedController/goUserCenter.do?uitype=5&callerCode=2c9bbe698172a06201824295293e0b5f2c9bbe698172a062&returnurl=${redirect_uri}`
            }
            return res;
        } else {
            if (code == 500) {
                Message.error('数据请求失败')
            }
        }

    },
    error => {
        Message.error('数据请求失败')
        // window.console.log(error)
        // router.push({
        //     path:'/error',
        //     query:{
        //         message:(error.response && error.response.data.message)||'数据请求失败'
        //     }
        // })
        return Promise.reject(error)
    }
)

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
    // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
    // return (process.env.NODE_ENV !== 'production' ? process.env.OPEN_PROXY && 'http://192.168.2.238:8090/huiqi' : '/huiqi/') + actionName
    // return 'http://192.168.2.238:8090/huiqi' + actionName
    return 'https://lyhqpt.com/huiqi' + actionName
}
/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
    var defaults = {
        't': new Date().getTime()
    }
    return openDefultParams ? merge(defaults, params) : params
}
/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
    var defaults = {
        't': new Date().getTime()
    }
    data = openDefultdata ? merge(defaults, data) : data
    return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

http.sPost = (url, data) => {
    return new Promise(resolve => http({
        url: http.adornUrl(url),
        method: "post",
        data: http.adornData(data, true)
    }).then((data) => resolve(data)));
}

http.sUpdate = (url, data) => {
    return new Promise(resolve => http({
        url: http.adornUrl(url),
        method: "post",
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then((data) => resolve(data)));
}
export default http
