import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import VueClipboard from 'vue-clipboard2'
import motion from './plugins/motion'
import http from './api/request'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment'

Vue.use(ElementUI, {size: 'medium'});
Vue.prototype.$message = ElementUI.Message;
// Vue.prototype.$api = api;
Vue.prototype.$http = http
Vue.prototype.$moment = moment
axios.defaults.withCredentials = true
Vue.config.productionTip = false
Vue.prototype.axios = axios
Vue.prototype.console = window.console
// 使用剪切板
Vue.use(VueClipboard)
// 使用转场动画
Vue.directive('motion', motion)

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
