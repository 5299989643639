<template>
  <div class="suspension_btn" v-if="controller !== 'index' || view !== ''">
    <div class="btn-item" @click="goToView('index')">
      <el-image :src="require('@/views/communal/image/index@2x.png')"></el-image>
    </div>
    <div class="btn-item" @click="goUser" v-if="controller === 'index' || controller === 'content'">
      <el-image :src="require('@/views/communal/image/person@2x.png')"></el-image>
    </div>
    <div class="btn-item" @click="goToView('index/policy_market')"
         v-if="controller === 'user'  || controller === 'content'">
      <el-image :src="require('@/views/communal/image/shop@2x.png')"></el-image>
    </div>

    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="30%" :modal="false"
        top="50px">

      <div style="text-align:center"><img :src="require('@/views/index/image/modal_warning.png')" alt=""></div>
      <div class="modalContent">
        <p class="subTitle" style="text-align:center;color:#ff8811;padding: 10px;">温馨提示</p>
        <div class="text-center">
          <div class="loginTip" style="text-align: left;">
            “龙岩市惠企政策平台”注册和登录功能现由“福建省社会用户实名认证和授权平台”提供统一支持，用户在首次登录时，需注册账号方可使用。完成账号注册后，按页面提示登录系统即可。具体操作步骤如下：<br>（1）点击“注册”，跳转注册界面<br>（2）选择注册类别，填写相关信息，完成注册，跳转登录页面<br>（3）按需选择登录方式登录即可<br>
            <div style="text-indent: 1em;">若有注册、登录问题，请致电0591-62623959，感谢您的理解与支持。</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="text-align:center">
    <el-button type="primary" @click="handleClick">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      controller: "",
      dialogVisible: false,
      view: ""
    };
  },
  activated() {
    const hashArr = window.location.hash.split("/");
    if (hashArr[1]) this.controller = hashArr[1];
    else this.controller = "index";

    if (hashArr.length > 2) this.view = hashArr[2];
    console.log(this.controller, this.view)
  },
  methods: {
    goUser() {
      const username = window.sessionStorage.getItem("username");
      if (typeof username != "string") this.dialogVisible = true;
      else this.goToView('user');
    },
    handleClick() {
      let origin = window.location.origin
      let redirect_uri = encodeURIComponent(origin + "/#/index?loginflag=true")
      let callerCode = "2c9bbe698172a06201824295293e0b5f2c9bbe698172a062";
      window.location.href = `https://mztapp.fujian.gov.cn:8304/dataset/UnifiedController/goLogin.do?callerCode=${callerCode}&returnurl=${redirect_uri}`;
    },
    goToView(url) {
      window.location.href = `/#/${url}`;
    }
  }
}
</script>

<style lang="scss" scoped>

.suspension_btn {
  position: fixed;
  bottom: 60px;
  right: 40px;
  z-index: 10;

  :nth-child(2) {
    margin-top: 30px;
  }

  :nth-child(3) {
    margin-top: 30px;
  }

  .btn-item {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 0.5;
    }
  }

}
</style>
