<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

  </div>
</template>

<script>
let _hmt = _hmt || [];
(function () {
  const hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?489cdda62383f11e6a062663bf78defc";
  const s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

import Vue from 'vue'
import top_view from "@/views/communal/compoents/top_view";
import foot from "@/views/communal/compoents/foot";
import suspension_btn from "@/views/communal/compoents/suspension_btn";

export default {
  name: "App",
  data: () => ({}),
  mounted() {
    this.$nextTick(() => {
      Vue.component("top_view", top_view);
      Vue.component("suspension_btn", suspension_btn);
      document.body.appendChild(((new (Vue.extend(foot))()).$mount()).$el);
    });
    const sUserAgent = navigator.userAgent;
    if (
        sUserAgent.indexOf("Android") > -1 ||
        sUserAgent.indexOf("iPhone") > -1 ||
        sUserAgent.indexOf("iPad") > -1 ||
        sUserAgent.indexOf("iPod") > -1 ||
        sUserAgent.indexOf("Symbian") > -1
    ) {
      location.href = "https://lyhqpt.com/hqzcpt/#" + this.$route.fullPath;
    }
  },
  methods: {
    handleClick() {
      let origin = window.location.origin
      let redirect_uri = encodeURIComponent(origin + "/#/index")
      let callerCode = "2c9bbe698172a06201824295293e0b5f2c9bbe698172a062";
      window.location.href = `https://mztapp.fujian.gov.cn:8304/dataset/UnifiedController/goLogin.do?callerCode=${callerCode}&returnurl=${redirect_uri}`;
    },
  }
};
</script>

<style lang="scss">
/*html {
  filter: grayscale(100%);
  height: 100%;
  overflow-y: auto !important;
}*/

.v-bottom-black-bar {
  // 适应底部小黑条
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

// 去除Google游览器自动填充账号出现的背景
input {
  background-color: transparent !important;
}

input:-webkit-autofill {
  -webkit-transition: background-color 1s ease-in-out 6000s;
  -webkit-text-fill-color: #fff !important;
}

// 清除vuetify的默认pre下的code伪元素和阴影
.v-application pre > code {
  box-shadow: none;

  &::before,
  &::after {
    content: none;
  }
}

pre[class*="language-"] {
  margin: auto 0 !important;
}

// 分页
.v-pagination .v-pagination__item {
  outline: none;
}

.v-application ul {
  padding: 0 !important;
}

.el-pagination {
  text-align: right;
  margin-top: 15px;
}
</style>