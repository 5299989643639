<template>
  <div class="login_view">
    <el-breadcrumb v-if="!isLogin" separator="|">
      <el-breadcrumb-item><a class="link" @click="handleLogin" href="javascript:;">登录</a></el-breadcrumb-item>
      <el-breadcrumb-item><a class="link" @click="handleRegist">注册</a></el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb v-if="isLogin" separator="|">
      <el-breadcrumb-item><a class="link" href="javascript:;"
                             @click="goToView('user')">欢迎你，{{ username }}</a></el-breadcrumb-item>
      <!--          <el-breadcrumb-item v-if="$route.name!='Index'"><a class="link" @click="$router.push({path:'/index'})">返回首页</a></el-breadcrumb-item>-->
      <el-breadcrumb-item><a class="link" @click="handleBack">退出登录</a></el-breadcrumb-item>
    </el-breadcrumb>
    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="30%" :modal="false"
        top="50px">

      <div style="text-align:center"><img :src="require('./image/modal_warning.png')" alt=""></div>
      <div class="modalContent">
        <p class="subTitle" style="text-align:center;color:#ff8811;padding: 10px;">温馨提示</p>
        <div class="text-center">
          <div class="loginTip" style="text-align: left;">
            “龙岩市惠企政策平台”注册和登录功能现由“福建省社会用户实名认证和授权平台”提供统一支持，用户在首次登录时，需注册账号方可使用。完成账号注册后，按页面提示登录系统即可。具体操作步骤如下：<br>（1）点击“注册”，跳转注册界面<br>（2）选择注册类别，填写相关信息，完成注册，跳转登录页面<br>（3）按需选择登录方式登录即可<br>
            <div style="text-indent: 1em;">若有注册、登录问题，请致电0591-62623959，感谢您的理解与支持。</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="text-align:center">
    <el-button type="primary" @click="handleClick">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    }
  },
  props: ['isLogin', 'username'],
  methods: {
    goToView(url) {
      window.location.href = `/#/${url}`;
    },
    handleLogin() {
      this.dialogVisible = true
    },
    handleClick() {
      let origin = window.location.origin
      let redirect_uri = encodeURIComponent(origin + "/#/index?loginflag=true")
      let callerCode = "2c9bbe698172a06201824295293e0b5f2c9bbe698172a062";
      window.location.href = `https://mztapp.fujian.gov.cn:8304/dataset/UnifiedController/goLogin.do?callerCode=${callerCode}&returnurl=${redirect_uri}`;
    },
    handleRegist() {
      let origin = window.location.origin
      let redirect_uri = encodeURIComponent(origin + "/#/index?loginflag=true")
      let callerCode = "2c9bbe698172a06201824295293e0b5f2c9bbe698172a062";
      window.location.href = `https://mztapp.fujian.gov.cn:8304/dataset/UnifiedController/goRegist.do?callerCode=${callerCode}&returnurl=${redirect_uri}`;
    },
    handleBack() {
      this.isLogin = false
      let origin = window.location.origin
      let redirect_uri = encodeURIComponent(origin + "/#/index")
      window.location.href = `https://mztapp.fujian.gov.cn:8304/dataset/UnifiedController/goUserCenter.do?uitype=5&callerCode=2c9bbe698172a06201824295293e0b5f2c9bbe698172a062&returnurl=${redirect_uri}`
      //  window.sessionStorage.removeItem('trustticket')
      //  window.sessionStorage.removeItem('loginflag')
      window.sessionStorage.removeItem('username')
      window.sessionStorage.removeItem('tycode')
      window.sessionStorage.removeItem('token')
      window.localStorage.removeItem('trustticket')
    },
  }
}
</script>

<style lang="scss" scoped>
.login_view {
  position: absolute;
  top: 23px;
  right: 70px;
  z-index: 1;
}

::v-deep .el-breadcrumb__separator {
  color: #333;
  font-weight: normal;
}

::v-deep .link {
  color: #333;
  font-weight: normal;
  cursor: pointer !important;
}
</style>
