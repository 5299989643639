<template>
  <div class="content">
    <div class="foot">
      <div>
        <span>主办：龙岩市发展和改革委员会</span>
        <span style="margin-left: 30px"><a style="color: white" href="https://beian.miit.gov.cn/" target="_blank">闽ICP备19024608号-2</a></span>
      </div>
      <div>技术支持：福建省众联网络科技有限公司</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot"
}
</script>

<style scoped>
.content {
  background: #f0f4f8;
  padding-top: 40px;
}

.foot {
  text-align: center;
  padding: 40px 0;
  font-size: 14px;
  color: #fff;
  background-color: #1156a3;
  line-height: 2;
}
</style>