/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
};

/**
 * 获取localStorage
 */
export const getStore = name => {
    if (!name) return;
    return window.localStorage.getItem(name);
};

/**
 * 删除localStorage
 */
export const removeStore = name => {
    if (!name) return;
    window.localStorage.removeItem(name);
};

/**
 * 存储sessionStorage
 * @param key
 * @param value
 */
export const sessionSetItem = (key, value) => {
    window.sessionStorage.setItem(key, JSON.stringify(value))
};

/**
 * 获取sessionStorage
 * @param key
 * @returns {any}
 */
export const sessionGetItem = (key) => {
    let result = window.sessionStorage.getItem(key)
    try {
        result = JSON.parse(result)
    } catch (error) {
        // eslint-disable-next-line no-self-assign
        result = result
    }
    return result
};

/**
 * 删除sessionStorage
 * @param key
 * @returns {any}
 */
export const sessionRemoveItem = (key) => {
    window.sessionStorage.removeItem(key)
};

/**
 * 清除sessionStorage
 * @param key
 * @returns {any}
 */
export const sessionClear = () => {
    window.sessionStorage.clear()
};

/**
 * 写cookies
 */
export const setCookie = (name, value, time) => {
    let strsec = time;
    let exp = new Date();
    exp.setTime(exp.getTime() + strsec * 1);
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
};

/**
 * 读取cookies
 */
export const getCookie = (name) => {
    let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    // eslint-disable-next-line no-cond-assign
    if (arr = document.cookie.match(reg)) return (arr[2]);
    else return null;
};

/**
 * 删除cookies
 */
export const delCookie = (name) => {
    let exp = new Date();
    exp.setTime(exp.getTime() - 1);
    let cval = getCookie(name);
    if (cval != null) document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
};

/**
 * 获取url后参数
 */
export const GetRequest = () => {
    let url = location.search; //获取url中"?"符后的字串

    let theRequest = new Object();
    if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]);
        }
    }
    return theRequest;
};