import * as types from './mutation-types'

const mutations = {
  [types.SET_TOKEN](state, token) {
    state.token = token;
  },
  // 发布订阅
  [types.PUSH_TOKEN](state, cancel) {
    // 也可以使用push
    state.ajaxToken = [...state.ajaxToken, cancel]
  },
  [types.CLEAR_TOKEN](state) {
    // 依次调用取消请求的方法
    state.ajaxToken.forEach(cancel => {
      if (cancel) {
        cancel()
      }
    });
    state.ajaxToken = []; // 清空数组
  },
  SET_INDEX_ACTIVE(state,active){
    state.indexActive=active;
  },
  SET_FRIENDS_ACTIVE(state,active){
    state.friendsActive=active;
  },
}

export default mutations;