
// eslint-disable-next-line no-unused-vars
function findIndex(list, song) {
  return list.findIndex((item) => {
    return item.id === song.id
  })
}

export const setIndexActive=function({commit},active){
  commit('SET_INDEX_ACTIVE',active);
}
export const setFriendsActive=function({commit},active){
  commit('SET_FRIENDS_ACTIVE',active);
}
// export const selectPlay = function ({commit, state}, {list, index}) {
//   commit(types.SET_SEQUENCE_LIST, list)
//   if (state.mode === playMode.random) {
//     let randomList = shuffle(list)
//     commit(types.SET_PLAYLIST, randomList)
//     index = findIndex(randomList, list[index])
//   } else {
//     commit(types.SET_PLAYLIST, list)
//   }
//   commit(types.SET_CURRENT_INDEX, index)
//   commit(types.SET_FULL_SCREEN, true)
//   commit(types.SET_PLAYING_STATE, true)
// }

// export const setCourseList = async function ({commit}, id){
// 	let res = await api.getNewChanceList({id,page});
// 	commit(types.SET_SEQUENCE_LIST, list);
// }